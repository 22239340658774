<template>
  <v-container fluid class="mb-2">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="12" sm="12" md="12">
        <v-card-text class="mt-1">
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-card color="red lighten-1" class="pt-2" elevation="3" dark>
                <v-card-text>
                  <ul>
                    <li>
                      Setiap Permohonan darah, harap disertai darah beku/darah
                      EDT 5cc minimal 3cc
                    </li>
                    <li>
                      Nama dan Identitas pasien pada formulir permintaan dan
                      contoh darahnya harus diisi
                    </li>
                    <li>
                      sebelum transfusi, cocokan label pada kantong darah dengan
                      labelnya dan diserta dengan identitas pasien yang akan
                      ditransfusi. bila ada ketidakcocokan segara kembalikan ke
                      BDRS
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="5" lg="5" sm="5">
                <v-card class="mt-3 mb-0">
                  <v-card-text>
                    <v-row class="pt-5 p-1">
                      <v-col
                        v-for="(v, i) in formBdrs.form"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-1 pt-1 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form[i] = e)"
                          :value="data.form[i]"
                          vclass="c-text-field"
                          :data="v.data"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="7" lg="7" sm="7">
                <v-card class="mt-3 mb-0">
                  <v-card-text>
                    <v-row class="pt-5 p-1">
                      <v-col
                        v-for="(v, i) in formBdrs.form_2"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-2 pt-2 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form_2[i] = e)"
                          :value="data.form_2[i]"
                          vclass="c-text-field"
                          :data="v.data"
                          :property="v.property"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-row>
                  <v-col cols="12" lg="12" md="12" sm="12">
                    <v-btn
                      color="primary"
                      @click="simpan"
                      medium
                      :disabled="DISABLEDBUTTONSIMPAN"
                      class="mt-2 btn-block"
                      >ORDER PERMINTAAN DARAH</v-btn
                    >
                  </v-col>
                </v-row>
                <v-card class="mt-3 mb-0">
                  <v-card-text>
                    <v-simple-table dense fixed-header height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Aksi</th>
                            <th class="text-center">Validasi Perawat</th>
                            <th class="text-center">Validasi DPJP</th>
                            <th class="text-center">Tanggal Diperlukan</th>
                            <th class="text-center">Tanggal Order</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in listOrderBDRS"
                            :key="item.id_order_bdrs"
                          >
                            <td
                              class="text-center"
                              style="vertical-align: middle"
                            >
                              <v-btn
                                fab
                                x-small
                                class="m-1"
                                :disabled="item.validasi_perawat == '1'"
                                @click="validasi(item)"
                                color="primary"
                                ><v-icon>mdi-file-check</v-icon></v-btn
                              >
                              <v-btn
                                fab
                                x-small
                                class="m-1"
                                :disabled="item.validasi_dpjp == '1'"
                                @click="validasi_dpjp(item)"
                                color="green"
                                ><v-icon>mdi-check-all</v-icon></v-btn
                              >
                              <v-btn
                                fab
                                x-small
                                class="m-1"
                                @click="liatblanko(item)"
                                color="yellow"
                                dark
                                ><v-icon>mdi-file</v-icon></v-btn
                              >
                            </td>
                            <td
                              class="text-center"
                              style="vertical-align: middle"
                            >
                              <span v-if="item.validasi_perawat == '0'"
                                ><v-icon class="text-danger">mdi-close</v-icon>
                                Belum Di Validasi</span
                              >
                              <span v-if="item.validasi_perawat == '1'"
                                ><v-icon class="text-success">mdi-check</v-icon>
                                Di Validasi</span
                              ><br />
                              <b v-if="item.validasi_perawat == '1'"
                                >&nbsp;{{
                                  item.detail_validasi_perawat.namaPegawai
                                }}
                                -
                                {{
                                  item.detail_validasi_perawat.tglValidasi
                                }}</b
                              >
                            </td>
                            <td
                              class="text-center"
                              style="vertical-align: middle"
                            >
                              <span v-if="item.validasi_dpjp == '0'"
                                ><v-icon class="text-danger">mdi-close</v-icon>
                                Belum Di Validasi</span
                              >
                              <span v-if="item.validasi_dpjp == '1'"
                                ><v-icon class="text-success">mdi-check</v-icon>
                                Di Validasi</span
                              ><br />
                              <b v-if="item.validasi_dpjp == '1'"
                                >&nbsp;{{
                                  item.detail_validasi_dokter.namaPegawai
                                }}
                                -
                                {{ item.detail_validasi_dokter.tglValidasi }}</b
                              >
                            </td>
                            <td
                              class="text-center"
                              style="vertical-align: middle"
                            >
                              {{ item.tgl_diperlukan }}
                            </td>
                            <td
                              class="text-center"
                              style="vertical-align: middle"
                            >
                              {{ item.tgl_order }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showDialogBdrs" max-width="980px">
        <v-card>
          <v-card-title class="headline"> Form Permintaan BDRS </v-card-title>
          <v-card-text>
            <div id="hasil"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import SmartWidget from '@/components/SmartWidget.vue'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  isEmpty
} from '../../plugins/supports'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget
  },
  name: 'DialogRadiologi',

  data () {
    return {
      showDialogBdrs: false,
      snackbar: false,
      valid: true,
      pesanError: 'Hello, Im a snackbar',
      waktuTimeOut: 200,
      listItem: [],
      listOrder: [],
      pesanOrder: 'List Order Radiologi',
      folioYangDipakai: '',
      items: '',
      DISABLEDBUTTONSIMPAN: false,
      keterangan: '',
      cekOrder: false,
      tab: '',
      id_order: 0,
      reg: '',
      rm: '',
      pegawai: '',

      sub_unit_id: '',
      searchTindakan: '',
      formBdrs: {
        demografi: {
          nama_pasien: {
            label: 'Nama Pasien',
            widget: 'wtext',
            data: null,
            col: 4
          },
          no_rekam_medis: {
            label: 'No.Rekam Medis',
            widget: 'wtext',
            data: null,
            col: 4
          },
          tanggal_lahir: { widget: 'wdate', data: null, col: 3 },
          cara_bayar: {
            label: 'Cara Bayar',
            widget: 'wtext',
            data: null,
            col: 4
          },
          folio_id: {
            widget: 'wtext',
            data: null,
            col: 4,
            label: 'No Folio'
          },
          reg_id: {
            widget: 'wtext',
            data: null,
            label: 'No Reg',
            col: 4
          },
          jenis_kelamin: {
            widget: 'wradio',
            data: [
              { text: 'Laki-Laki', value: 'Laki-Laki' },
              { text: 'Perempuan', value: 'Perempuan' }
            ],
            col: 12
          }
        },
        form: {
          kepada: {
            label: 'Dokter DPJP',
            widget: 'wautocomplatesingle',
            data: [],
            col: 12
          },
          tanggal_permintaan: {
            label: 'Tanggal Permintaan',
            widget: 'wdatenormal',
            data: null,
            col: 6
          },
          tangga_diperlukan: {
            label: 'Tanggal Diperlukan',
            widget: 'wdatenormal',
            data: null,
            col: 6
          },
          diagnosa: {
            label: 'Diagnosa Klinis',
            widget: 'wtextarea',
            data: null,
            col: 6
          },
          alasan_transfusi: {
            label: 'Alasan Transfusi',
            widget: 'wtextarea',
            data: null,
            col: 6
          },
          hb: {
            label: '(HB/Leukosit/Trombosit)',
            widget: 'wtext',
            data: null,
            col: 6
          },
          pernah_transfusi: {
            label: 'Pernah Transfusi Sebelumnya',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 6
          },
          kapan_transfusi: {
            label: 'Kapan Transfusi',
            widget: 'wtext',
            data: null,
            col: 6
          },
          reaksi_transfusi: {
            label: 'Reaksi Transfusi',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 6
          },
          gejala_reaksi_transfusi: {
            label: 'Gejala Reaksi Transfusi',
            widget: 'wtext',
            data: null,
            col: 12
          },
          serologi_golongan_darah: {
            label: 'Apakah Pernah Diperiksa serologi Golongan Darah?',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          dimana_serologi: {
            label: 'Dimana?',
            widget: 'wtext',
            data: null,
            col: 6
          },
          kapan_serologi: {
            label: 'Kapan?',
            widget: 'wtext',
            data: null,
            col: 6
          },
          hasil_golongan: {
            label: 'Hasil',
            widget: 'wradio',
            data: [
              { text: 'O', value: 'O' },
              { text: 'A', value: 'A' },
              { text: 'B', value: 'B' },
              { text: 'AB', value: 'AB' }
            ],
            col: 12
          },
          pasien_wanita_khusus: {
            label: 'Khusus Untuk Pasien Wanita Jumlah Kehamilan Sebelumnya?',
            widget: 'wtext',
            data: null,
            col: 12
          },
          pasien_wanita_abortus: {
            label: 'Pernah Abortus?',
            widget: 'wtext',
            data: null,
            col: 12
          },
          pasien_wanita_hdn: {
            label: 'Adakah sebelumnya penyakit hemolitik pada baya (HDN)?',
            widget: 'wtext',
            data: null,
            col: 12
          }
        },
        form_2: {
          harap_diberikan: {
            label: 'Harap Diberikan',
            widget: 'wtext',
            data: null,
            col: 12,
            property: { attrs: { suffix: 'cc/kantong', type: 'number' } }
          },
          darah_lengkap: {
            widget: 'wradio',
            col: 12,
            label: 'Darah Lengkap (Whole Blood)',
            data: [
              {
                text: 'Segar/Baru',
                value: '',
                field: 'auto'
              },

              {
                text: 'Biasa',
                value: '',
                field: 'auto'
              }
            ]
          },
          packed_red: {
            widget: 'wradio',
            col: 12,
            label: 'RED CELLS CONCENTRATE',
            data: [
              {
                text: 'Packed Red Celis',
                value: '',
                field: 'auto'
              },

              {
                text: 'Lain-Lain',
                value: '',
                field: 'auto'
              }
            ]
          },
          komponen: {
            widget: 'wradio',
            col: 12,
            label: 'Komponen',
            data: [
              {
                text: 'Thrombocyte (TC)',
                value: '',
                field: 'auto'
              },

              {
                text: 'Fresh Frozen Plasma (FFP)',
                value: '',
                field: 'auto'
              },

              {
                text: 'Cryoprecipitare Anti',
                value: '',
                field: 'auto'
              },
              {
                text: 'Lain-Lain',
                value: '',
                field: 'auto'
              }
            ]
          }
        }
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        },
        form: {
          kepada: '',
          tanggal_permintaan: '',
          tangga_diperlukan: '',
          diagnosa: '',
          alasan_transfusi: '',
          hb: '',
          pernah_transfusi: '',
          kapan_transfusi: '',
          reaksi_transfusi: '',
          gejala_reaksi_transfusi: '',
          serologi_golongan_darah: '',
          dimana_serologi: '',
          kapan_serologi: '',
          hasil_golongan: '',
          pasien_wanita_khusus: '-',
          pasien_wanita_abortus: '-',
          pasien_wanita_hdn: '-'
        },
        form_2: {
          harap_diberikan: '',
          darah_lengkap: '',
          packed_red: '',
          komponen: ''
        }
      },
      listOrderBDRS: [],
      folio: '',
      url_emr: 'http://emr.rs-syafira.com/',
      dialogShow: false,
      itemTindakan: [],
      tindakan: [],
      listHistoryOrder: [],
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => {
        this.dialogShow = false
      },
      // diaglogAcc
      dialogShowAcc: false,
      dialogTitleAcc: 'Konfirmasi Untuk Pemeriksaan Item Radiologi Ini',
      dialogMessageAcc: 'Apakah Item Ini Sudah Di Acc Untuk Di Periksa',
      dialogProgressAcc: false,
      idorderpenunjang: '',
      dialogDisableNBtnAcc: false,
      dialogDisablePBtnAcc: false,
      dialogActionNBtnAcc: () => {
        this.dialogShowAcc = false
      },
      dialogActionPBtnAcc: () => {
        // this.dialogShow = false
      },
      rules: {
        required: (value) => {
          //   console.log(value + 'ISI DARI INI APAYA')
          return !isEmpty(value) || 'Tidak Boleh Kosong'
          //   return false
        }
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    if (this.$route.query.no_rm != null) {
      this.pegawai = JSON.parse(localStorage.getItem('user'))
      this.getDataListBankDarah()
      this.getDataPasien()

      console.log(this.pegawai + ' Data Pegawai')
    }
  },
  mounted () {
    setTimeout(() => {
      this.getDataListBankDarah()
    }, 1000)
  },
  methods: {
    getDataListBankDarah (v) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'bdrs/index?no_rm=' + this.$route.query.no_rm,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.listOrderBDRS = results
          } else {
            this.listOrderBDRS = []
          }
        },
        'JSON'
      )
    },
    validasi_dpjp (v) {
      // eslint-disable-next-line camelcase, no-unused-vars
      const id_order = v.id_order_bdrs
      // eslint-disable-next-line camelcase, no-unused-vars
      const no_order = v.no_order
      // eslint-disable-next-line camelcase, no-unused-vars
      const tgl_diperlukan = v.tgl_diperlukan
      // eslint-disable-next-line camelcase, no-unused-vars
      const nama_pasien = v.detail_order.data.demografi.nama_pasien
      // eslint-disable-next-line no-unused-vars
      const namaDokter = v.dpjp.text

      // console.log()

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Yakin?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Validasi',
        cancelButtonText: 'Tidak Jadi Batal',
        // eslint-disable-next-line camelcase
        html:
          'Saya <b>' +
          namaDokter +
          '</b> Ingin Validasi Data Ini Dengan Nama Pasien : <b>' +
          // eslint-disable-next-line camelcase
          nama_pasien +
          '</b> Untuk Darah Pada Tanggal : <b>' +
          // eslint-disable-next-line camelcase
          tgl_diperlukan +
          '</b> Yang Akan Diberikan : ' +
          v.harap_diberikan +
          ' cc'
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef, camelcase
          $.post(
            // eslint-disable-next-line camelcase
            baseUrlV2 +
              'bdrs/validasi-bdrs?id_order=' +
              // eslint-disable-next-line camelcase
              id_order +
              '&type=Dokter',
            { data: namaDokter },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                this.getDataListBankDarah(v.no_rm)
                successMsg(msg)
              } else {
                errorMsg(msg)
              }
            }
          )
        } else {
          // resetBtnLoading(btn, html)
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    validasi (v) {
      console.log(v)

      // eslint-disable-next-line camelcase, no-unused-vars
      const id_order = v.id_order_bdrs
      // eslint-disable-next-line camelcase, no-unused-vars
      const no_order = v.no_order
      // eslint-disable-next-line camelcase, no-unused-vars
      const tgl_diperlukan = v.tgl_diperlukan
      // eslint-disable-next-line camelcase, no-unused-vars
      const nama_pasien = v.detail_order.data.demografi.nama_pasien
      // console.log(nama_pasien)
      // console.log(no_order)
      // console.log(tgl_diperlukan)
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Yakin?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Validasi',
        cancelButtonText: 'Tidak Jadi Batal',
        // eslint-disable-next-line camelcase
        html:
          'Saya <b>' +
          this.pegawai.employee_name +
          '</b> Ingin Validasi Data Ini Dengan Nama Pasien : <b>' +
          // eslint-disable-next-line camelcase
          nama_pasien +
          '</b> Untuk Darah Pada Tanggal : <b>' +
          // eslint-disable-next-line camelcase
          tgl_diperlukan +
          '</b> Yang Akan Diberikan : ' +
          v.harap_diberikan +
          ' cc'
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef, camelcase
          $.post(
            // eslint-disable-next-line camelcase
            baseUrlV2 + 'bdrs/validasi-bdrs?id_order=' + id_order,
            { data: this.pegawai.employee_name },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                this.getDataListBankDarah(v.no_rm)
                successMsg(msg)
              } else {
                errorMsg(msg)
              }
            }
          )
        } else {
          // resetBtnLoading(btn, html)
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    getDataPasien () {
      if (this.$route.query.no_rm != null) {
        // eslint-disable-next-line no-undef
        $.get(
          baseUrl + 'emr/detail-pasien?rm=' + this.$route.query.no_rm,
          (res) => {
            // eslint-disable-next-line no-unused-vars
            const { con, msg, results } = res

            if (con) {
              this.dataPatient = results
              this.data.demografi = this.dataPatient
              this.data.demografi.reg_id = this.$route.query.registration_id
              this.data.demografi.folio_id = this.$route.query.folio_id
              this.data.demografi.jenis_kelamin = {
                text: results.jenis_kelamin,
                value: results.jenis_kelamin
              }
              // console.log('JENIS KELAMIN => ' + JSON.stringify(results))
              setTimeout(() => {
                if (results.jenis_kelamin.text === 'Laki-Laki') {
                  console.log('LAKI-LAKI KESINI')
                  this.data.form.pasien_wanita_khusus = '--'
                  this.data.form.pasien_wanita_abortus = '--'
                  this.data.form.pasien_wanita_hdn = '--'
                }
              }, 1000)
            } else {
              errorMsg('Data Pasien Tidak Ditemukan Dalam Database')
            }
          },
          'JSON'
        )
      }
    },
    simpan () {
      if (this.$refs.form.validate()) {
        this.DISABLEDBUTTONSIMPAN = true
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Perhatian! Apakah Anda Yakin?',
          text: 'Ingin Mengorder Permintaan Darah?.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Order Permintaan Darah',
          cancelButtonText: 'Tidak Jadi Batal',
          html: ''
        }).then((result) => {
          if (result.value) {
            const data = {
              no_rm: this.$route.query.no_rm,
              registration_id: this.$route.query.registration_id,
              folio_id: this.$route.query.folio_id,
              employee_id: this.pegawai.employee_id,
              data: this.data,
              type: 'R.Jalan'
            }
            console.log(data)

            // eslint-disable-next-line no-undef
            $.post(
              baseUrlV2 + 'bdrs/simpan',
              data,
              (res) => {
                // eslint-disable-next-line no-unused-vars
                const { con, msg, results } = res
                if (con) {
                  //   this.data =
                  this.DISABLEDBUTTONSIMPAN = false
                  this.getDataListBankDarah(this.$route.query.no_rm)
                  this.$refs.form.reset() // resetBtnLoading(btn, html)
                  successMsg(msg)
                } else {
                  errorMsg(msg)
                }
              },
              'JSON'
            )
          } else {
            // resetBtnLoading(btn, html)
            errorMsg('Nggak Jadi Deh !!!')
          }
        })
      }
    },
    liatblanko (item) {
      this.showDialogBdrs = true
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'bdrs/cetak-bdrs?id_order=' + item.id_order_bdrs,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    }
  }
}
</script>
<style></style>
