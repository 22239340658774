
<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      :persistent="persistentDialog"
      max-width="700"
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div style="font-size: 17px" class="mb-1" v-html="message" />
          <v-progress-linear
            v-show="progress"
            color="primary"
            indeterminate
            rounded
            height="6"
          />
          <v-divider class="mt-4 mb-4"></v-divider>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Tanggal Periksa Labor"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <p>
            Tanggal Yang Dipilih: <strong>{{ date }}</strong>
          </p>
          <v-select
            outlined
            dense
            class="py-0"
            v-model="jenisPermintaan"
            :items="itemsPilih"
            label="Jenis Permintaan"
          ></v-select>

          <v-select
            outlined
            dense
            class="py-0"
            v-model="poAcc"
            :items="po"
            label="Acc By"
          ></v-select>
          <v-checkbox v-model="checkbox" label="Acc Management"></v-checkbox>
          <v-textarea
            class="mt-0"
            auto-grow
            v-if="checkbox"
            outlined
            label="Keterangan ACC"
            rows="4"
            row-height="20"
            v-model="keteranganAcc"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="error darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            Tutup
          </v-btn>
          <v-btn color="green darken-1" dark @click="simpanAcc">
            Order Laboratorium
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { apiKedua, successMsg, baseUrl, errorMsg } from '../plugins/supports'

export default {
  name: 'DialogAcc',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },

    positiveBtnLabel: {
      type: String,
      default: 'Order Ulang',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },

    title: {
      type: String,
      default: null,
      required: true
    },
    message: {
      type: String,
      default: '',
      required: true
    },
    idOrder: {
      type: String,
      default: '',
      required: false
    },
    progress: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: (vm) => ({
    checkbox: '',
    keteranganAcc: '',
    jenisPermintaan: '',

    itemsPilih: ['PERMINTAAN', 'CITO'],
    radiologiPilih: ['RADIOLOGI IGD', 'RADIOLOGI CENTRAL'],
    po: ['dr. Tyna Candra', 'dr. Ririn Okti Helmi', 'SPV', 'Dokter', 'Lainnya', 'PIC PT'],
    poAcc: '',
    poRadiologi: '',
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false
  }),
  computed: {

  },
  watch: {

  },
  methods: {

    simpanAcc () {
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin MengAcc Item-Item Labor Ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Acc Item Ini',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-unused-vars
          var data = {
            checkbox: this.checkbox,
            keteranganAcc: this.keteranganAcc,
            poAcc: this.poAcc,
            poRadiologi: this.poRadiologi,
            idOrder: this.idOrder,
            jenisPermintaan: this.jenisPermintaan,
            date: this.date
          }
          // eslint-disable-next-line no-undef
          $.post(
            baseUrl + 'labor/acc',
            data,
            (response) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = response
              if (con) {
                successMsg(msg)
              } else {
                errorMsg(msg + JSON.stringify(results))
              }
            },
            'JSON'
          )
        } else {
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    form () {
      // eslint-disable-next-line no-unused-vars
      const id = this.idOrder

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'radiologi/cek-acc?id_order=' + id,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            console.log(results)
            // this.checkbox = results.acc_management
            // this.keteranganAcc = results.keterangan_acc
            // this.po = results.acc_by
            // this.status_orderan = results.itemsPilih
          } else {
            this.checkbox = ''
            this.keteranganAcc = ''
            this.poAcc = ''
            this.jenisPermintaan = ''
          }
        },
        'JSON'
      )
    }
  }
}
</script>

  <style scoped>
</style>
